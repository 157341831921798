import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
export default function about() {
  return (
    <Layout>
      <Seo
        title="About Us"
        description="Having fallen in love with the island more than 20 years ago and
          quickly making it their home, the High Life Team have a comprehensive
          knowledge of the island, making them the perfect agent to help plan
          your Ibiza vacation."
      />
      <Content />
    </Layout>
  )
}

const Content = () => {
  return (
    <div className="p-2 lg:p-8 container mx-auto">
      <h1 className="pb-[30px]">About Us</h1>
      <div className="grid gap-2">
        <p className="font-property text-sm">
          Having fallen in love with the island more than 20 years ago and
          quickly making it their home, the High Life Team have a comprehensive
          knowledge of the island, making them the perfect agent to help plan
          your Ibiza vacation. With access to some of the island’s most
          exclusive properties and intimate relationships with the island’s most
          exclusive venues, the team curate bespoke stays, customised to their
          clients requirements.
        </p>
        <p className="font-property text-sm">
          The perfect stay starts with the perfect villa. Each of the properties
          in our portfolio is inspected by our rentals team to ensure they meet
          our own high standards. Once we have confirmed your villa we work
          closely with you to understand your requirements and help to plan any
          aspect of your stay so that you do not have to lift a finger. The team
          are headed by Ajay Sharma whose resume and contacts list have made him
          one of the island’s premier facilitators.
        </p>
      </div>
    </div>
  )
}
